import ProfileDropdownList from "components/layout/profileDropdownList";
import React, { useState } from "react";
import ExpandIcon from "../icons/expand.icon";
import ProfileIcon from "../icons/profileIcon.icon";

import { useRENAuth } from "helpers/security/ren-auth-client-side";

const ProfileDropdown = () => {
  const [open, setOpen] = useState(false);
  const drop = React.useRef<HTMLDivElement>(null);
  const { renUser } = useRENAuth();

  function handleClick(e) {
    // Clicked element = e.target
    // On some old browsers (e.g. Edge < 15), Element.closest isn't supported. This feature is so "unimportant",
    // that we simply skip it for those browsers...
    if (
      e.target.closest &&
      drop.current &&
      !e.target.closest(`.${drop.current.className}`) &&
      open
    ) {
      setOpen(false);
    }
  }

  React.useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  return (
    <div className="c-profile-dropdown" ref={drop}>
      <button
        className="c-profile-button"
        onClick={() => setOpen(open => !open)}
      >
        <div className="c-profile-button__profileicon">
          <ProfileIcon />
        </div>
        <div>
          <div className="u-roboto500">{renUser.fullName}</div>
        </div>
        <div className="c-profile-button__arrowicon">
          <ExpandIcon />
        </div>
      </button>
      {open && (
        <div className="c-profile-open__wrapper">
          <nav className="c-profile-open">
            <ul className="c-profile-open__list">
              <ProfileDropdownList />
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default ProfileDropdown;
