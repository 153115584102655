import ProfileDropdown from "components/layout/profileDropdown";
import ProfileDropdownList from "components/layout/profileDropdownList";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import CloseIcon from "../icons/close.icon";
import ExpandIcon from "../icons/expand.icon";
import LogoIcon from "../icons/logo.icon";
import LoginButton from "./loginbutton";
import RenLink from "../shared/RenLink";

import { useRENAuth } from "helpers/security/ren-auth-client-side";

const Menu = ({ menu: { menuLoggedOut, menuLoggedIn } }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const router = useRouter();
  const { renUser, isProtectedRoute } = useRENAuth();

  useEffect(() => {
    const handleRouteChange = url => {
      console.log("App is changing to: ", url);
      setIsMenuOpen(false);
    };

    router.events.on("routeChangeStart", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, []);

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];

    if (isMenuOpen) {
      body.classList.add("u-menu-overlay-container");
    } else {
      body.classList.remove("u-menu-overlay-container");
    }
  }, [isMenuOpen]);

  const filterByAllowedRoles = element => {
    if (element.allowedRoles && isProtectedRoute) {
      return renUser.verifyAllowedRoles(element.allowedRoles);
    }
    return element;
  };

  const menu = isProtectedRoute
    ? {
        ...menuLoggedIn,
        menuItems: menuLoggedIn.menuItems.filter(filterByAllowedRoles),
        menuItemsDropDown:
          menuLoggedIn.menuItemsDropDown.filter(filterByAllowedRoles),
      }
    : menuLoggedOut;

  return (
    <>
      <a
        aria-label="Hopp til innhold"
        className="skip-link"
        href="#hovedinnhold"
      >
        Hopp til hovedinnhold
      </a>
      <nav aria-label="Hovedmeny" className="o-wrapper c-menu">
        <RenLink classes="c-menu__logo" aria-label="Gå til ren.no" href="/">
          <LogoIcon />
          <span
            style={{
              visibility: "hidden",
              height: "1px",
              width: "1px",
              display: "block",
              color: "black",
            }}
          >
            Forside
          </span>
        </RenLink>
        <ul>
          {menu.menuItems.map(menuItem => {
            return (
              <li
                key={menuItem._key}
                className="c-menu__list-item c-menu__list-item-customizable"
              >
                <RenLink href={menuItem}>{menuItem.text}</RenLink>
              </li>
            );
          })}
          <li key="expandable" className="c-menu__list-item">
            <button
              className="c-menu__button"
              onClick={() => setIsMenuOpen(true)}
            >
              Meny <ExpandIcon />
            </button>
          </li>

          {isProtectedRoute && (
            <li className="c-menu__list-item c-menu__list-item-profile">
              <ProfileDropdown />
            </li>
          )}

          {!isProtectedRoute && (
            <li
              key="loginbutton"
              className="c-menu__list-item c-menu__login-button"
            >
              <LoginButton
                text={renUser.authenticated ? "Min side" : "Logg inn"}
              />
            </li>
          )}
        </ul>
      </nav>

      <nav
        aria-label="Undermeny"
        className={isMenuOpen ? "c-menu__overlay" : "u-hide"}
      >
        <div className="o-wrapper o-grid o-grid__end">
          <button
            className="c-menu__overlay-button "
            onClick={() => setIsMenuOpen(false)}
          >
            <CloseIcon />
            <div className="u-fontSmall u-hide--mobile">Lukk</div>
          </button>
        </div>
        <div className="o-wrapper u-padding-top-bottom--large">
          <div className="c-menu__dropdown-content">
            <ul className="c-menu__dropdown-main-menu">
              {menu.menuItems.map(menuItem => {
                return (
                  <li key={menuItem._key}>
                    <RenLink href={menuItem}>{menuItem.text}</RenLink>
                  </li>
                );
              })}
            </ul>
            <ul
              className={`c-menu__dropdown-list c-menu__dropdown-list${
                !(
                  menu.menuItemsDropDownColumnTwo &&
                  menu.menuItemsDropDownColumnTwo.length > 0
                )
                  ? "--2column"
                  : ""
              }`}
            >
              {menu.menuItemsDropDown.map(menuItem => {
                return (
                  <li key={menuItem._key}>
                    <RenLink href={menuItem}>{menuItem.text}</RenLink>
                  </li>
                );
              })}
            </ul>
            {isProtectedRoute &&
              menu.menuItemsDropDownColumnTwo &&
              menu.menuItemsDropDownColumnTwo.length > 0 && (
                <ul className="c-menu__dropdown-list c-menu__dropdown-list ">
                  {menu.menuItemsDropDownColumnTwo.map(menuItem => {
                    return (
                      <li key={menuItem._key}>
                        <RenLink href={menuItem}>{menuItem.text}</RenLink>
                      </li>
                    );
                  })}
                </ul>
              )}
            {isProtectedRoute && (
              <ul className="c-menu__dropdown-list">
                <ProfileDropdownList />
              </ul>
            )}

            {!isProtectedRoute && (
              <div key="loginbutton" className="">
                <LoginButton
                  text={renUser.authenticated ? "Min side" : "Logg inn"}
                />
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

Menu.propTypes = {
  menu: PropTypes.object,
  title: PropTypes.string,
};

export default Menu;
