import NProgress from "nprogress";
import Link from "next/link";
const LoginButton = ({ text }) => {
  return (
    <Link
      className="c-login-button"
      href="/innlogget"
      onClick={() => NProgress.start()}
    >
      {text}
    </Link>
  );
};

export default LoginButton;
