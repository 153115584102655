const ExpandIcon = () => (
  <svg
    width="24"
    height="15"
    viewBox="0 0 24 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.25 5L11.5 10.75L5.75 5L4 6.75L11.5 14.25L19 6.75L17.25 5Z"
      fill="currentColor"
    />
  </svg>
);
export default ExpandIcon;
