const LogoIcon = () => (
  <svg
    width="111"
    height="28"
    viewBox="0 0 111 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M45.5594 17.6667H40.9206V27.8333H35.1221V0H45.3937C48.7071 0 51.1922 0.666667 53.0146 2.16667C54.837 3.66667 55.6653 5.66667 55.6653 8.33333C55.6653 10.1667 55.334 11.8333 54.5056 13.1667C53.6773 14.5 52.5176 15.5 50.6952 16.1667L56.6594 27.6667V28H50.5295L45.5594 17.6667ZM40.9206 13H45.5594C47.0504 13 48.0445 12.6667 48.8728 11.8333C49.7012 11.1667 50.0325 10 50.0325 8.83333C50.0325 7.5 49.7012 6.5 48.8728 5.66667C48.2101 4.83333 47.0504 4.5 45.3937 4.5H40.7549V13H40.9206Z"
        fill="#0A6252"
      />
      <path
        d="M79.5219 15.8333H68.5876V23.3333H81.5099V28H62.7891V0H81.3443V4.66667H68.4219V11.3333H79.5219V15.8333Z"
        fill="#0A6252"
      />
      <path
        d="M111 28H105.201L94.1012 9.66667V28H88.3027V0H94.1012L105.201 18.5V0H111V28Z"
        fill="#0A6252"
      />
    </g>
    <path d="M28 0H0V6.33333H28V0Z" fill="currentColor" />
    <path d="M28 10.8335H0V17.1668H28V10.8335Z" fill="currentColor" />
    <path d="M28 21.667H0V28.0003H28V21.667Z" fill="currentColor" />
    <defs>
      <clipPath id="clip0">
        <rect width="111" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default LogoIcon;
