const CloseIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 10L10 30"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="square"
    />
    <path
      d="M10 10L30 30"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="square"
    />
  </svg>
);
export default CloseIcon;
